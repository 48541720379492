@charset "utf-8";

$link-color: #F30963;

@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

ul.date-archive-year {
  > li {
    margin-top: 1em;
	margin-bottom: 0;
	font-weight: bold;
  }
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      display: inline;
    }

    li:not(:first-child):before {
      content: " • ";
    }
  }
}
